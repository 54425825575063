<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="50%"
      :fullscreen="isfullscreen"
      persistent
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon @click="openDialog()" v-bind="attrs" v-on="on"> edit </v-icon>
      </template>
      <v-card flat class="px-4 pt-4">
        <!-- dialog toolbar -->
        <v-toolbar height="45" flat>
          <h5>
            تعديل ال
            {{ this.$route.meta.single }}
          </h5>
          <v-spacer />
          <v-btn small icon fab @click="isfullscreen = !isfullscreen">
            <v-icon small>{{
              isfullscreen ? "mdi-arrow-collapse" : "mdi-arrow-expand"
            }}</v-icon>
          </v-btn>
          <v-btn @click="close()" fab small icon color="grey">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="mx-4" />

        <!-- form body -->
        <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
          <v-container>
            <v-card flat class="py-4 px-1">
              <v-row>
                <!-- fullName -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">الاسم الكامل <span class="required">*</span></h6>
                  <v-text-field
                    v-model="body.fullName"
                    :rules="[$global.state.required()]"
                    required
                    outlined
                    hide-details="auto"
                    dense
                    filled
                    placeholder="الاسم الكامل"
                  >
                  </v-text-field>
                </v-col>

                <!-- userName -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">إسم المستخدم<span class="required">*</span></h6>
                  <v-text-field
                    v-model="body.userName"
                    :rules="[enRules(body.userName)]"
                    required
                    outlined
                    hide-details="auto"
                    dense
                    filled
                    hint="يجب إدخال إسم المستخدم بالإنكليزي"
                    placeholder="إسم المستخدم ( بالإنكليزي )"
                  >
                  </v-text-field>
                </v-col>

                <!-- phoneNumber -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">رقم الهاتف</h6>
                  <v-text-field
                    v-model="body.phoneNumber"
                    v-mask="$phoneMask"
                    dir="ltr"
                    placeholder="+964 ### ### ####"
                    filled
                    dense
                    outlined
                    hide-details="auto"
                  />
                </v-col>

                <!-- email -->
                <!-- <v-col cols="12" md="6">
                                    <h6 class="mb-1">البريدالإلكتروني</h6>
                                    <v-text-field v-model="body.email" :rules="[
                                        $global.state.required(),
                                        $global.state.emailFormat(),
                                    ]" outlined hide-details="auto" dense filled placeholder="البريدالإلكتروني">
                                    </v-text-field>
                                </v-col> -->

                <!-- provinceId -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">المحافظة <span class="required">*</span></h6>
                  <v-autocomplete
                    v-model="body.provinceId"
                    item-text="name"
                    item-value="id"
                    :items="$global.state.provinces"
                    clearable
                    dense
                    filled
                    :rules="[$global.state.required()]"
                    placeholder="المحافظة"
                    no-data-text="لا توجد بيانات"
                    hide-details
                    outlined
                  />
                </v-col>
                <!-- user type -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">نوع المتسخدم <span class="required">*</span></h6>
                  <v-autocomplete
                    v-model="body.type"
                    item-text="text"
                    item-value="value"
                    :items="userType"
                    clearable
                    dense
                    filled
                    :rules="[$global.state.required()]"
                    placeholder="نوع المستخدم"
                    no-data-text="لا توجد بيانات"
                    hide-details
                    outlined
                  />
                </v-col>
                <!-- addres -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">العنوان الكامل <span class="required">*</span></h6>
                  <v-text-field
                    v-model="body.addres"
                    outlined
                    hide-details="auto"
                    dense
                    filled
                    placeholder="العنوان الكامل"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="mb-1">المستلم المعتمد <span class="required">*</span></h6>
                  <v-text-field
                    v-model="body.authorizedRecipient"
                    outlined
                    hide-details="auto"
                    dense
                    filled
                    placeholder="المستلم المعتمد"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="mb-1">رقم الهاتف للمستلم المعتمد</h6>
                  <v-text-field
                    v-model="body.authorizedRecipientPhoneNumber"
                    v-mask="$phoneMask"
                    dir="ltr"
                    placeholder="+964 ### ### ####"
                    filled
                    dense
                    outlined
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="mb-1">تاريخ الأستحقاق <span class="required">*</span></h6>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateText"
                        placeholder="تاريخ الأستحقاق "
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        filled
                        no-data-text="لا توجد بيانات"
                        hide-details
                        clearable
                        @click:clear="clearDate()"
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="body.dueDate"
                      :active-picker.sync="activePicker"
                      min="1950-01-01"
                      @input="save(body.dueDate)"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="mb-1">تكلفة التحويل <span class="required">*</span></h6>
                  <v-text-field
                    v-model="body.conversionCost"
                    outlined
                    hide-details="auto"
                    dense
                    filled
                    placeholder="تكلفة التحويل"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="mb-1">نسبة الربح <span class="required">*</span></h6>
                  <v-text-field
                    v-model="body.profitPercentage"
                    outlined
                    hide-details="auto"
                    dense
                    filled
                    placeholder="نسبة الربح"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-divider class="ml- mt-8" />
              <!-- actions -->
              <div class="mt-4">
                <v-btn
                  type="submit"
                  :loading="loading"
                  color="primary"
                  class="btn"
                  :disabled="!valid"
                >
                  حفظ
                  <v-icon small right>fi fi-rr-disk</v-icon>
                </v-btn>
                <v-btn @click="close()" text depressed class="mr-2 btn">إلغاء</v-btn>
              </div>
            </v-card>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "edit",
  props: ["user", "id"],
  data: () => ({
    showPassword: false,
    isfullscreen: false,
    dialog: false,
    loading: false,
    valid: true,
    userType: [
      { text: "شركاء", value: 2 },
      { text: "مستخدمين", value: 1 },
    ],
    body: {
      fullName: "",
      userName: "",
      // email: "",
      phoneNumber: "",
      provinceId: null,
      addres: "",
      type: 0,
      authorizedRecipient: null,
      authorizedRecipientPhoneNumber: null,
      conversionCost: 0,
      profitPercentage: 0,
      dueDate: null,
      // groupId:"",
    },
    roles: [],
    activePicker: null,
    dateText: null,
  }),
  methods: {
    save(date) {
      this.dateText = date;
      this.menu = false;
    },
    clearDate() {
      this.dateText = null;
      this.body.dueDate = null;
    },
    enRules(v) {
      const englishRegex = /^[A-Za-z][A-Za-z0-9]*$/; // Regex to match only English letters and spaces
      if (!v) {
        return "هذا الحقل مطلوب"; // Arabic: This field is required
      } else if (!englishRegex.test(v)) {
        return "الرجاء إدخال حروف إنجليزية فقط و بدون فراغ"; // Arabic: Please enter only English characters
      }
      return true;
    },
    reset() {
      this.body = {
        fullName: "",
        userName: "",
        // email: "",
        phoneNumber: "",
        provinceId: null,
        addres: "",
        type: 0,
        imageUrl: "",
        authorizedRecipient: null,
        authorizedRecipientPhoneNumber: null,
        conversionCost: 0,
        profitPercentage: 0,
        dueDate: null,
      };
    },
    openDialog() {
      this.body.fullName = this.user.fullName;
      this.body.addres = this.user.addres;
      // this.body.email = this.user.email;
      this.body.conversionCost = this.user.conversionCost;
      this.body.authorizedRecipient = this.user.authorizedRecipient;
      this.body.authorizedRecipientPhoneNumber = this.user.authorizedRecipientPhoneNumber;
      this.body.profitPercentage = this.user.profitPercentage;
      this.body.phoneNumber = this.user.phoneNumber;
      this.body.userName = this.user.userName;
      this.body.provinceId = this.user.provinceId;
      this.body.type = this.user.type;
      if (this.user.dueDate == "0001-01-01T00:00:00") {
        const currentDate = new Date();
        const formattedDate = new Intl.DateTimeFormat("en-SE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          // hour: "2-digit",
          // minute: "2-digit",
          // second: "2-digit",
          // hour12: false,
          // Use 24-hour format
        }).format(currentDate);

        this.body.dueDate = formattedDate;
        this.dateText = formattedDate;
      } else {
        const date = new Date(this.user.dueDate);

        // Format the date
        const formattedDate = date.toLocaleDateString("en-SE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });

        this.body.dueDate = formattedDate;
        this.dateText = formattedDate;
      }
    },
    submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.editItem();
      }
    },
    close() {
      this.reset();
      this.resetValidation();
      this.dialog = false;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async editItem() {
      this.loading = true;
      await this.$http
        .put(`${this.$route.meta.endPoint}/${this.id}`, this.body)
        .then((res) => {
          this.$emit(`refresh`);
          this.close();
          this.$store.dispatch("toastification/setToast", {
            message: `${res.data.message}`,
            type: "success",
          });
        })
        .catch((err) => {
          var message = err.response.message ?? err.response.data.message;
          var result = err.response.data.result;
          this.$store.dispatch("toastification/setToast", {
            message: `${message}`,
            type: "error",
          });
          if (Array.isArray(result)) {
            result.forEach((element) => {
              this.$store.dispatch("toastification/setToast", {
                message: `${element.description}`,
                type: "error",
              });
            });
          }
          //   this.$store.dispatch("toastification/setToast", {
          //     message: `${err.response.data.message}`,
          //     type: "error",
          //   });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
